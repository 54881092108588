:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  /* background-color: #242424; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

/* body {
} */

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

.accordion-button {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  border: none !important;
  background-color: transparent;
  display: flex;
  justify-content: start;
  padding-left: 10px;
}

button {
  border-radius: 8px;
  /* border: 1px solid transparent; */
  /* padding: 0.6em 1.2em; */
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  /* background-color: #1a1a1a; */
  cursor: pointer;
  /* transition: border-color 0.25s; */
}

/* button:hover {
  border-color: #646cff;
} */
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.cost-container {
  width: 100%;
  padding-left: 16px;
  /* Assuming 'px-4' corresponds to 16px */
  padding-right: 16px;
  /* Assuming 'px-4' corresponds to 16px */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.cost-label {
  font-weight: bold;
}

.cost-value {
  margin-top: 0;
  /* To ensure the H3 is aligned properly */
}

#drawingFile::file-selector-button,
#formFileLines::file-selector-button {
  background-color: var(--primary);
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 10px;
  margin-right: 5%;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  /* font-family: "kohinoor", Arial, sans-serif; */
  line-height: 1.5;
  font-weight: 400;
  --black: #444;
  font-size: 15px;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  /* background-color: #242424; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --test-border: 0.1rem solid black;
  --primary: #b13a25;
  --primary-background-color: #b13a25;
  --secondary-color: #767676;
}

@font-face {
  font-family: "Kohinoor";
  src: url("fonts/KohinoorDevanagari-Medium.woff2") format("woff2"),
    url("fonts/KohinoorDevanagari-Medium.ttf") format("truetype"),
    url("fonts/KohinoorDevanagari-Medium.svg#KohinoorDevanagari-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kohinoor";
  src: url("fonts/KohinoorDevanagari-Light.woff2") format("woff2"),
    url("fonts/KohinoorDevanagari-Light.ttf") format("truetype"),
    url("fonts/KohinoorDevanagari-Light.svg#KohinoorDevanagari-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kohinoor";
  src: url("fonts/KohinoorDevanagari-Bold.woff2") format("woff2"),
    url("fonts/KohinoorDevanagari-Bold.ttf") format("truetype"),
    url("fonts/KohinoorDevanagari-Bold.svg#KohinoorDevanagari-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kohinoor";
  src: url("fonts/KohinoorDevanagari-Regular.woff2") format("woff2"),
    url("fonts/KohinoorDevanagari-Regular.ttf") format("truetype"),
    url("fonts/KohinoorDevanagari-Regular.svg#KohinoorDevanagari-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kohinoor";
  src: url("fonts/KohinoorDevanagari-Semibold.woff2") format("woff2"),
    url("fonts/KohinoorDevanagari-Semibold.ttf") format("truetype"),
    url("fonts/KohinoorDevanagari-Semibold.svg#KohinoorDevanagari-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

a,
button {
  transition: all ease 0.2s;
}

.bg-primary {
  background-color: var(--primary-background-color) !important;
}

.text-primary {
  color: var(--primary) !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: var(--primary) !important;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-size: 15px;
  font-family: "kohinoor";
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

.btn-lg {
  background-color: var(--primary-background-color);
  color: white;
  padding: 10px;
  width: 100%;
}

.form-group {
  margin-bottom: 30px;
  position: relative;
}

.form-control,
.bootstrap-select .btn.dropdown-toggle {
  width: 100%;
  border: 1px solid #ddd;
  background: #fff;
  height: 40px;
  line-height: 2.4;
  border-radius: 0;
  padding: 10px;
  font-weight: normal;
  border-radius: 5px;
}

.bootstrap-select .btn.dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 10px;
  right: 25px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark:after {
  border-color: var(--primary);
  border-width: 0 0.1em 0.1em 0;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ddd;
  color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form-control:focus {
  border: 1px solid var(--primary-background-color);
  box-shadow: none;
}

.form-control:hover {
  border: 1px solid var(--primary-background-color);
}

.form-control[type="file"] {
  overflow: hidden;
  padding: 0 10px;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control::-webkit-file-upload-button {
  padding: 0rem 0.75rem;
  margin: 0rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #333;
  display: inline-block;
  height: 100%;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 2px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::file-selector-button {
  padding: 0rem 0.75rem;
  margin: -0.25rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #333 !important;
  background-color: #f4f4f4 !important;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e8e8e8;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e8e8e8;
}

input[type="radio"] {
  border: 2px solid #ddd;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 10px;
}

input[type="radio"]:checked {
  background-color: var(--primary);
  background: linear-gradient(60deg, var(--primary) 0%, var(--primary) 100%);
  border-color: var(--primary);
}

input[type="radio"]:focus {
  outline: none;
}

.text-xs {
  font-size: 15px;
}

.btn {
  border-radius: 0 !important;
  padding: 10px 20px 7px;
  font-weight: 500;
}

.btn-danger {
  background: var(--primary) !important;
}

.btn-danger:hover {
  background: var(--primary) !important;
}

.btn-outline-danger {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-outline-danger:hover {
  background: var(--primary) !important;
  color: #fff !important;
}

.btn+.btn {
  margin-left: 15px;
}

button:focus {
  outline: none;
}

.MainContainer {
  height: auto !important;
  padding: 45px 60px !important;
}

.btn-primary:hover {
  background: var(--primary) !important;
}

button.bg-primary:hover,
button.bg-primary:focus {
  background: var(--primary) !important;
}

input.w-full {
  height: 40px !important;
}

label {
  margin-bottom: 5px !important;
  display: inline-block;
}

select.form-control {
  padding: 0 30px 0 10px !important;
  height: 40px !important;
}

textarea.form-control {
  min-height: 60px !important;
  height: auto;
  padding: 0 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-5px {
  margin-bottom: 5px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}

input[type="checkbox"] {
  border-color: #888;
  margin-top: -2px;
}

input[type="checkbox"]+label {
  margin-bottom: 0 !important;
}

h3:not(.text-xl)+.text-primary {
  margin-top: -25px;
}

h3+.text-primary {
  margin-bottom: 30px;
}

h5 {
  margin-bottom: 5px;
  font-weight: 600;
}

h6 {
  margin-bottom: 5px;
}

.user-dropdown .dropdown-toggle {
  padding: 9px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  color: #777;
  font-size: 20px;
}

.user-dropdown .dropdown-toggle:after {
  display: none;
}

.profle-dropdown {
  position: absolute;
  z-index: 2;
  border-radius: 0;
  display: block;
  left: auto;
  right: 60px;
  top: 75px;
  min-width: 0;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.profle-dropdown>button {
  display: block;
  width: 100%;
  margin: 0 !important;
  padding: 0.25rem 1rem;
  text-align: left;
}

.profle-dropdown>button:hover {
  background: #eee;
}

@media (max-width: 767px) {
  .MainContainer {
    padding: 30px !important;
  }

  .profle-dropdown {
    right: 30px;
    top: 45px;
  }
}

.table {
  margin-bottom: 0;
}

.table th {
  background: #f7f7f7;
  white-space: nowrap;
  font-weight: 500;
  vertical-align: middle !important;
}

.wrapTh {
  white-space: normal !important;
}

.table th,
.table td {
  text-align: left;
}

.table th:first-child,
.table td:first-child {
  min-width: 50px !important;
}

.table .form-control[type="file"] {
  width: 240px;
}

.table tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

/* .table td {
  padding: 10px 0 10px 30px;
} */

@media print {
  .noPrint {
    display: none;
  }

  button,
  .stepsBar {
    display: none !important;
  }
}

.uploadLink {
  font-size: 12px;
  position: relative;
  color: #0b79f0;
  padding: 0 !important;
  height: auto !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 30px);
  text-align: left;
  background: none !important;
}

table .uploadLink {
  position: relative;
  bottom: auto;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9) url(/public/infinite-spinner.svg) no-repeat center center;
  background-size: 100px auto;
}

.table td {
  vertical-align: middle;
}

.pdf_dropdown a {
  color: #ffffff;
}

.pdf_dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
}

.form-check {
  display: flex;
}

.form-check input[type="checkbox"] {
  align-self: center;
}

.form-check input[type="checkbox"]+.form-check-label {
  margin-left: 5px;
}

.instuctions * {
  font-size: 15px !important;
  line-height: 1.7 !important;
  font-family: "Kohinoor" !important;
}

.error {
  font-size: 10px;
  color: red;
}

.success {
  font-size: 10px;
  font-weight: 600;
  color: #5cb27f;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked {
  background-color: var(--primary-background-color);
  border-color: var(--primary-background-color);
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  opacity: 1;
}

.info {
  color: #919191;
}

.tooltip-text {
  visibility: hidden;
  width: 17vw;
  /* width: 100% !important; */
  background-color: var(--primary-background-color);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position the tooltip above the text */
  left: 85%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;

  /* test */
  /* visibility: visible !important;
  opacity: 1 !important; */
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 0.9;
}

table.tableInsideTable td {
  white-space: nowrap;
}
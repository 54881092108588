.pageHeading {
  margin: 20px;
}

.selectedTask {
  border: 2px solid #ff0000;
}

.requireField {
  font-size: 12px;
  color: red;
  padding-left: 2px;
  margin: none;
  padding: none;
}

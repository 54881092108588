.custom_file_upload {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px solid #b13a25;
  background-color: rgba(255, 255, 255, 1);
  padding: 0.3rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
  width: 115px;
}

.custom_file_upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_file_upload .text span {
  font-weight: 400;
  color: rgba(75, 85, 99, 1);
}

.custom_file_upload input {
  display: none;
}
/* 
.inputType_text {
}

.inputType_number {
}
.inputType_file {
}
.inputType_textarea {
}
.inputType_radio {
} */

.radio {
  padding: 20px;
  border: 2px solid black;
}

.radio_label {
  padding-left: 5px;
}

.radio_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

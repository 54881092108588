.MainContainer {
  width: 100%;
  height: calc(100vh - 15vh);
  /* border: var(--test-border); */
  position: relative;
}

.stepsBar {
  width: 100%;
  text-align: center;
}

.step {
  flex: 1 0 0;
}

.step-icon {
  position: relative;
}

.step-icon:after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  background-color: #ddd;
}

.step-icon .step-circle {
  position: relative;
  background: #fff;
  border: 1px solid #c6c6c6;
  width: 20px;
  height: 20px;
  z-index: 1;
  border-radius: 50%;
  margin: auto;
}

.step-icon svg {
  position: relative;
  border: 1px solid;
  width: 20px;
  height: 20px;
  z-index: 1;
  padding: 3px;
  border-radius: 50%;
  margin: auto;
}

.complete .step-icon svg {
  background: #27ae60;
  border-color: #27ae60;
}

.pending .step-icon svg {
  background: #ffc83c;
  border: 1px solid #ffc83c;
}

/* .step-icon svg, .step-icon .rounded-full{
    position: relative;
    z-index: 1;
  } */

.step:last-child .step-icon::after {
  display: none;
}

.complete .step-icon:after,
.recheck_complete .step-icon:after {
  background: #27ae60;
}

.pending .step-icon:after,
.recheck .step-icon:after {
  background: #ffc83c;
}

.current .step-icon .step-circle {
  border-color: var(--primary);
}

.current.step .step-icon .step-circle:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: var(--primary);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 920px;
  }
}

/* .btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url(
    data:image/svg + xml;charset=utf-8,
    %3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"%3E%3Cpathd="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z"/%3E%3C/svg%3E
  );
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: #0000 var(--bs-btn-close-bg) center / 1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: initial;
  height: 1em;
  opacity: var(--bs-btn-close-opacity);
  padding: 0.25em;
  width: 1em;
  color: var(--bs-btn-close-color);
} */

.modal .status {
  width: 140px;
  border: none;
  padding: 13px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  right: 67px;
  top: 10px;
}

.modal .status.approved {
  color: #fff;
  background: #5ac070;
}

.modal .status.rejected {
  color: #fff;
  background: #ff4b12;
}

.modal .status.pending {
  color: #fff;
  background: #c79820;
}

.modal .status.selected {
  color: #fff;
  background: #f16142;
}

.status {
  width: 90px;
  border: 1px solid;
  padding: 2px 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.status.approved {
  color: #568e5b;
  background: #e1ffed;
  border-color: #84ca8b;
}

.status.rejected {
  color: #c03838;
  background: #ffeff0;
  border-color: #ff8b96;
}

.status.selected {
  color: #f16142;
  background: #ffeee8;
  border-color: #ff743c;
}

.status.registered {
  color: #7a99b8;
  background: #fff;
  border-color: #9bb4cc;
}

.status.pending {
  color: #c79820;
  background: #fff9d7;
  border-color: #f5d276;
}

.status.in_progress {
  color: #277fbf;
  background: #ddf1ff;
  border-color: #80c9ff;
}
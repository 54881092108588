.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.article {
  max-width: 700px;
  padding: 50px;
  margin: 0 auto;
  text-align: center;
}

.heading {
  font-size: 50px;
  font-weight: 100;
  text-align: center;
}

.text {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  font-size: 20px;
  color: black;
}

.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
  background-color: #39374e !important;
  color: #babdbf !important;
  border-color: #39374e !important;
}

.nav-link-gdc-selected {
  font-weight: bold !important;
}

div.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
div.font-roboto {
  font-family: "Robot", sans-serif;
}
div.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
/* div.rtl{ */
div.rtl {
  direction: rtl !important;
  text-align: right;
}
.sticky-top {
  width: calc(100% - 280px);
  position: fixed;
}
.marginTop {
  margin-top: 7.2% !important;
}

.dropdown-item:hover {
  cursor: pointer;
}

.sidebar_text {
  font-size: 11px;
  margin-top: 5px;
}

/* .p-dialog-header {
/* .p-dialog-header {
  display: none !important;
} */

.p-dialog-header-icon {
  background-color: unset !important;
}

.p-column-filter-menu-button {
  background-color: unset !important;
}

/* .p-button {
  background: #f2f2f2 !important;
  color: black;
  margin-bottom: 1%;
} */

.p-paginator-element {
  background: unset !important;
}

.p-paginator-element:hover {
  background: #f2f2f2;
  cursor: pointer;
}
.p-row-toggler {
  background-color: unset !important;
}

/* .p-column-header-content {
  justify-content: flex-start;
} */

.p-accordion-toggle-icon {
  color: black;
}

.p-dialog-header-icon {
  background-color: unset !important;
}

.p-tag {
  width: 70px;
  padding: 4px;
}

.p-text-sm {
  width: 50px;
}

.p-tag-value {
  font-size: 10px;
}

/* .p-dropdown {
  width: 160px;
} */

.nav-link {
  margin-top: 5px;
}

.nav-link svg {
  height: 25px;
  width: 25px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #ff5252;
}

.p-tabview-title {
  color: black;
}

.p-tabview-panels {
  padding: 0;
}

.p-tabview-nav {
  margin-bottom: 0;
}

.p-datatable {
  font-size: 14px; /* Adjust the font size as per your requirement */
}

/* Example: Reduce font size of table headers */
.p-datatable .p-datatable-thead th {
  font-size: 14px; /* Adjust the font size as per your requirement */
  padding: 10px 4px;
}
.p-datatable-thead {
  z-index: 0;
}

/* Example: Reduce font size of table cells */
.p-datatable .p-datatable-tbody td {
  font-size: 12px; /* Adjust the font size as per your requirement */
}

.p-datatable .p-datatable-tbody tr {
  height: 22px; /* Adjust the row height as per your requirement */
  line-height: 22px; /* Adjust the line height as per your requirement */
}

/* Example: Reduce padding of table cells */
.p-datatable .p-datatable-tbody td {
  padding: 2px 6px; /* Adjust the padding as per your requirement */
}

.p-inputtext,
.p-multiselect-label {
  padding: 4px;
  font-size: 14px;
}

.p-tabview-panels .p-unselectable-text {
  margin: 10px 5px;
}
.p-tabview-panels .p-tabview-nav-link {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  padding: 10px 21px;
}
.p-tabview-panels .p-tabview-selected .p-tabview-nav-link {
  background: #ff5252;
}

#formFile::file-selector-button {
  background-color: #fe4550 !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 12px !important ;
  padding: 2px 7px !important;
  margin: 3px 8px 2px 0px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: background 0.2s ease-in-out !important;
}

.p-inputtextarea {
  min-height: 31px;
  margin-top: 5px;
  height: 31px;
}

.p-column-title {
  width: max-content;
}

.p-sortable-column-icon {
  width: 12px;
  height: 12px;
}

.react-flow__attribution {
  display: none;
}

.p-datepicker-trigger {
  border-radius: 0 !important;
}
@media (max-width: 769px) {
  .card {
    width: 100% !important ;
  }
}

.form-horizontal {
  width: 100%;
}

.text-danger {
  color: red !important;
}

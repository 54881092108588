@import "./assets/plugins/bootstrap/css/bootstrap.min.css";

@import "./assets/css/main.css";

@import "./assets/css/theme2.css";

@import "./assets/css/custom.css";

@import "./assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css";

@import "./assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  font-family: Inter;
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.p-datatable-wrapper {
  overflow: visible;
}

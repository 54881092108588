.table th {
  background: none !important;
}

.three-dot-button {
  background-color: #b13b25 !important;
  color: rgb(255, 255, 255);
  border: none;
  width: 50px;
  height: 30px;
  /* border-radius: 10px;
  border: 1px solid black; */
}
.p-datatable-wrapper {
  overflow: visible !important;
}

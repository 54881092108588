.container {
  display: flex;
}

.form {
  display: flex;
  width: 100%;
}

.leftArea {
  display: flex;
  justify-content: space-between;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table tr,
.table td {
  border: 1px solid #000000;
  padding: 8px;
  text-align: center;
}

.table th {
  border: 1px solid #000000;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f2f2f2;
}

.rightArea {
  display: flex;
  justify-content: space-between;
}

.contentcol {
  width: 40%;
}

.content1 {
  display: flex;
}

.processFluid,
.temperature,
.flowRate,
.heatTransfer {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.sensor {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}

.sensorpage {
  width: 100%;
}

.parentdiv {
  display: flex;
}

.d-flex.align-items-center {
  display: flex;
  align-items: center;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.error {
  color: red;
  font-size: 0.875rem;
}

@media screen and (max-width: 768px) {
  .parentdiv {
    flex-direction: column;
  }
}